import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/theme/src/layouts/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "hello"
    }}>{`Hello`}</h1>
    <p>{`My name is Josef, and I am a full-stack JavaScript developer currently employed as a Frontend UI Developer at `}<a href="https://twitter.com/IBM" target="_blank" rel="noopener noreferrer">{`@IBM`}</a>{`. When I’m not thinking about work or my personal projects I might be playing with my two
goofball dogs `}{`—`}{` Marvin and Maverick, hanging out with my friends enjoying our favorite
music tracks, or I might just be listening to deep house music playing strategy video games. I’m a
sucker for movies with great soundtracks (i.e. written by John Williams) and Reese’s
seasonals. Over the years I’ve taken an interest in web development. `}<br /><a parentName="p" {...{
        "href": "/about"
      }}>{`Read more.`}</a></p>
    <blockquote>
      <p parentName="blockquote">{`The power of imagination makes us infinite `}{`—`}{` John Muir`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      